import React, { useEffect } from "react"
import SEO from "../components/SEO"
import Footer from "../components/footer"
import Header from "../components/header"
import { display } from "../utils"
const loadText = require("src/utils").loadText
const data = loadText("documentation")
import "../styles/documentation.less"
const contentModify = (title, content) => {
  let newContent = content
  if (title === "Cookie Policy") {
    newContent += '<div id="ot-sdk-cookie-policy"></div>'
  }
  if (title === "Privacy Policy") {
    newContent = newContent.replace(
      "<strong>Your California Privacy Rights</strong>",
      "<div id='ccpa' style='position: relative; bottom: 70px; height:1px;'></div><strong>Your California Privacy Rights</strong>"
    )
  }
  return newContent
}
const Documentation = ({ pageContext }) => {
  const { title, content, created_at, updated_at } = pageContext
  useEffect(() => {
    if ((window as any).OneTrust) {
      ;(window as any).OneTrust.initializeCookiePolicyHtml()
    }
  }, [])
  return (
    <div className="documentation">
      <SEO title={title} description={title} />
      <Header logo="/logo-blue.png" />
      <div className="documentation-title">
        <div
          className="font-36 semi-bold t-color title"
          // style={{ padding: "0 48px" }}
        >
          {title}
        </div>
        <div className="mt-16 sub">
          {/* {`${data.subject.created_at}: ${display(created_at)}`} */}
          {/* <span className="divider">|</span> */}
          {`${data.subject.updated_at}: ${display(updated_at)}`}
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: contentModify(title, content),
        }}
        className="documentation-content"
      />
      <Footer />
    </div>
  )
}

export default Documentation
